import React, { useEffect } from 'react';
import './styles/index.scss';
import './styles/coming-soon.css';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';

import HubspotFormGeneral from '../components/hubspot-forms/hubspot-form-general';
import { UseTheme } from '../context/ThemeContext';

const useContactUs = ({ data }) => {

  const { theme } = UseTheme()
  return (
    <div className="background-theme contact-us">
      <div className='coming-soon'>
        <Container>
          <Row className='d-flex-center-all-row'>
            <Col md={5}>
              <h1 dangerouslySetInnerHTML={{ __html: data.allDatoCmsContactUsPage.nodes[0].titleAndParagraph[0].title }}></h1>
              <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsContactUsPage.nodes[0].titleAndParagraph[0].paragraph }}></p>
            </Col>
            <Col md={7}>
              {theme === 'dark' ?
                (<HubspotFormGeneral portalId='23479554' formId='d9f9d22a-4349-4ba4-8566-2e792a19d29e' />)
                :
                (<HubspotFormGeneral portalId='23479554' formId='e1c05630-2b02-483c-be49-2ebe88517735' />)
              }
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default useContactUs

export const Head = ({ data }) => (
  <Seo
    title="Contact Us"
    description={data.allDatoCmsContactUsPage.nodes[0].seo.description}
    image={data.allDatoCmsContactUsPage.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query ContactUsQuery {
    allDatoCmsContactUsPage {
      nodes {
        titleAndParagraph {
          paragraph
          title
        }
        seo {
          description
          image {
            url
          }
        }
      }
    }
  }
` 